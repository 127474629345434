import { getUserRolesAsync } from "@vatsim-vnas/js-libs/api/data";
import React, { useEffect, useState } from "react";
import { Outlet } from "react-router-dom";
import { Footer, Header, Sidebar } from "src/components/main";
import { AccessExpiringModal, AccessExpiringModalSpec, ConfirmOverwriteModal } from "src/components/ui/modal";
import { ScreenSize } from "src/enums";
import {
  confirmOverwriteSelector,
  forceSaveArtcc,
  loadArtcc,
  loadArtccsTrainingAirportSummaries,
  loadArtccsTrainingScenarios,
  loadArtccsTrainingWeather,
  loadNonNasFacilities,
  loadedSelector,
  resetConfirmOverwrite,
  screenSizeSelector,
  sidebarIsCollapsedSelector,
  toggleSidebar,
  unsavedDataSelector,
  useAppDispatch,
  useAppSelector,
  userSelector,
} from "src/redux";
import * as S from "src/styles/ui";
import { addWindowClass, removeWindowClass } from "src/utils";

function Main() {
  const loaded = useAppSelector(loadedSelector);
  const confirmOverwrite = useAppSelector(confirmOverwriteSelector);
  const unsavedData = useAppSelector(unsavedDataSelector);
  const editableArtccs = useAppSelector(userSelector).artccSummaries;
  const screenSize = useAppSelector(screenSizeSelector);
  const menuSidebarIsCollapsed = useAppSelector(sidebarIsCollapsedSelector);
  const user = useAppSelector(userSelector);
  const dispatch = useAppDispatch();

  const [accessExpiringModalSpec, setAccessExpiringModalSpec] = useState<AccessExpiringModalSpec>({
    show: false,
    userRole: undefined!,
  });

  const loadAsync = async () => {
    dispatch(loadNonNasFacilities());

    let selectedArtccId = localStorage.getItem("selected-artcc");
    if (!selectedArtccId || !editableArtccs.find((a) => a.id === selectedArtccId)) {
      selectedArtccId = editableArtccs[0].id;
    }

    dispatch(loadArtcc(selectedArtccId));
    dispatch(loadArtccsTrainingAirportSummaries(selectedArtccId));
    dispatch(loadArtccsTrainingWeather(selectedArtccId));
    dispatch(loadArtccsTrainingScenarios(selectedArtccId));
  };

  useEffect(() => {
    (async () => {
      const res = await getUserRolesAsync();

      if (!res.ok) {
        return;
      }

      const oneMonthFromNow = new Date();
      oneMonthFromNow.setUTCMonth(oneMonthFromNow.getUTCMonth() + 1);

      for (const role of res.data!) {
        if (
          role.userId === user.cid &&
          role.accessExpiresAt &&
          role.accessExpiresAt < oneMonthFromNow &&
          new Date(role.accessExpiresAt).toISOString() !== localStorage.getItem(`${role.id}-access-expiring`)
        ) {
          setAccessExpiringModalSpec({ show: true, userRole: role });
          return;
        }
      }
    })();
  }, []);

  useEffect(() => {
    addWindowClass("sidebar-mini");
    loadAsync();
  }, []);

  useEffect(() => {
    removeWindowClass("sidebar-closed");
    removeWindowClass("sidebar-collapse");
    removeWindowClass("sidebar-open");
    if (menuSidebarIsCollapsed && screenSize === ScreenSize.Large) {
      addWindowClass("sidebar-collapse");
    } else if (menuSidebarIsCollapsed && (screenSize === ScreenSize.Small || screenSize === ScreenSize.Medium)) {
      addWindowClass("sidebar-open");
    } else if (!menuSidebarIsCollapsed && screenSize !== ScreenSize.Large) {
      addWindowClass("sidebar-closed");
      addWindowClass("sidebar-collapse");
    }
  }, [screenSize, menuSidebarIsCollapsed]);

  useEffect(() => {
    window.onbeforeunload = unsavedData ? () => "" : null;
    return () => {
      window.onbeforeunload = null;
    };
  }, [unsavedData]);

  const handleCloseAccessExpiringModal = () => {
    localStorage.setItem(
      `${accessExpiringModalSpec.userRole.id}-access-expiring`,
      new Date(accessExpiringModalSpec.userRole.accessExpiresAt!).toISOString(),
    );
    setAccessExpiringModalSpec((p) => ({ ...p, show: false }));
  };

  return (
    <>
      <div className="wrapper">
        {!loaded ? (
          <div className="preloader flex-column justify-content-center align-items-center">
            <S.PulsingLogo className="pulse" src="/img/logo.png" alt="CRC Logo" />
          </div>
        ) : (
          <>
            <Header />
            <Sidebar />
            <div className="content-wrapper pb-4">
              <div className="pt-3" />
              <section className="content">
                <Outlet />
              </section>
            </div>
            <Footer />
            <div id="sidebar-overlay" role="presentation" onClick={() => dispatch(toggleSidebar())} />
          </>
        )}
      </div>
      <ConfirmOverwriteModal
        show={confirmOverwrite}
        onDiscard={() => window.location.reload()}
        onForceSave={() => {
          dispatch(resetConfirmOverwrite());
          dispatch(forceSaveArtcc());
        }}
      />
      <AccessExpiringModal
        show={accessExpiringModalSpec.show}
        userRole={accessExpiringModalSpec.userRole}
        onClose={handleCloseAccessExpiringModal}
      />
    </>
  );
}

export default Main;
