import { faCircleExclamation } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { CanDeleteSpec } from "@vatsim-vnas/js-libs/utils";
import React from "react";
import { Col, Modal, Row } from "react-bootstrap";

interface DeleteModalProps {
  show: boolean;
  action?: string;
  boldItem?: boolean;
  itemName?: string;
  canDeleteSpec?: CanDeleteSpec;
  onClose: (confirmDelete: boolean) => void;
}

export interface DeleteModalSpec<T = undefined> {
  show: boolean;
  action?: string;
  boldItem?: boolean;
  itemName?: string;
  item?: T;
  canDeleteSpec?: CanDeleteSpec;
}

function DeleteModal({
  show,
  action = "Delete",
  boldItem = true,
  itemName = "",
  canDeleteSpec = undefined,
  onClose,
}: Readonly<DeleteModalProps>) {
  const formattedName = boldItem ? <b>{itemName}</b> : itemName;

  if (canDeleteSpec && !canDeleteSpec.canDelete) {
    return (
      <Modal show={show} onHide={() => onClose(false)} backdrop="static">
        <Modal.Header className="dark-mode">
          <Modal.Title>
            <FontAwesomeIcon icon={faCircleExclamation} className="mr-2 text-danger" /> Error
          </Modal.Title>
        </Modal.Header>
        <Modal.Body className="dark-mode">
          <Row>
            <Col>
              Unable to {action.toLowerCase()} {formattedName}:
              <ul>{canDeleteSpec?.errors.map((e) => <li key={e}>{e}</li>)}</ul>
            </Col>
          </Row>
        </Modal.Body>
        <Modal.Footer className="dark-mode">
          <button type="button" className="btn btn-secondary" onClick={() => onClose(false)}>
            OK
          </button>
        </Modal.Footer>
      </Modal>
    );
  }

  return (
    <Modal show={show} onHide={() => onClose(false)} backdrop="static">
      <Modal.Header className="dark-mode">
        <Modal.Title>Confirm {action}</Modal.Title>
      </Modal.Header>
      <Modal.Body className="dark-mode">
        <Row>
          <Col>
            Are you sure you want to {action.toLowerCase()} {formattedName}?
            <br />
            This cannot be undone.
          </Col>
        </Row>
      </Modal.Body>
      <Modal.Footer className="dark-mode">
        <button type="button" className="btn btn-secondary" onClick={() => onClose(false)}>
          Cancel
        </button>
        <button className="btn btn-danger" type="submit" onClick={() => onClose(true)}>
          {action}
        </button>
      </Modal.Footer>
    </Modal>
  );
}

export default DeleteModal;
