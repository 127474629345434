import { UserRole } from "@vatsim-vnas/js-libs/models/authorization";
import { artccRoleToString, getArtccRoleArticle } from "@vatsim-vnas/js-libs/models/data-api";
import { dateToZuluString } from "@vatsim-vnas/js-libs/utils";
import React, { useMemo } from "react";
import { Col, Modal, Row } from "react-bootstrap";

interface AccessExpiringModalProps {
  show: boolean;
  userRole: UserRole;
  onClose: () => void;
}

export interface AccessExpiringModalSpec {
  show: boolean;
  userRole: UserRole;
}

function AccessExpiringModal({ show, userRole, onClose }: Readonly<AccessExpiringModalProps>) {
  const message = useMemo(() => {
    if (!userRole?.role || !userRole.accessExpiresAt) {
      return "";
    }

    const artcc = <b>{userRole.artccId}</b>;
    const roleArticle = getArtccRoleArticle(userRole.role);
    const role = <b>{artccRoleToString(userRole.role)}</b>;
    const expirationDate = <b>{dateToZuluString(userRole.accessExpiresAt)}</b>;

    return (
      <>
        Your access to {artcc} as {roleArticle} {role} will expire on {expirationDate}. Please contact a {artcc}{" "}
        administrator to renew your access before it expires.
      </>
    );
  }, [userRole]);

  return (
    <Modal backdrop="static" show={show} size="lg">
      <Modal.Header className="dark-mode">
        <Modal.Title>Access Expiring</Modal.Title>
      </Modal.Header>
      <Modal.Body className="dark-mode">
        <Row>
          <Col>{message}</Col>
        </Row>
      </Modal.Body>
      <Modal.Footer className="dark-mode">
        <button className="btn btn-secondary" type="button" onClick={onClose}>
          OK
        </button>
      </Modal.Footer>
    </Modal>
  );
}

export default AccessExpiringModal;
