import { UserRole } from "@vatsim-vnas/js-libs/models/authorization";
import { artccRoleToString, getArtccRoleArticle } from "@vatsim-vnas/js-libs/models/data-api";
import React, { useMemo } from "react";
import { Col, Modal, Row } from "react-bootstrap";

interface RenewUserAccessModalProps {
  show: boolean;
  userRole: UserRole;
  onClose: (confirm: boolean) => void;
}

export interface RenewUserAccessModalSpec {
  show: boolean;
  userRole: UserRole;
}

function RenewUserAccessModal({ show, userRole, onClose }: Readonly<RenewUserAccessModalProps>) {
  const message = useMemo(() => {
    if (!userRole?.role) {
      return "";
    }

    const userName = (
      <b>
        {userRole.firstName} {userRole.lastName}
      </b>
    );
    const artcc = <b>{userRole.artccId}</b>;
    const roleArticle = getArtccRoleArticle(userRole.role);
    const role = <b>{artccRoleToString(userRole.role)}</b>;

    return (
      <>
        Are you sure you want to renew {userName}'s access to {artcc} as {roleArticle} {role} for another year?
      </>
    );
  }, [userRole]);

  return (
    <Modal backdrop="static" show={show} size="lg">
      <Modal.Header className="dark-mode">
        <Modal.Title>Confirm Renew User Access</Modal.Title>
      </Modal.Header>
      <Modal.Body className="dark-mode">
        <Row>
          <Col>{message}</Col>
        </Row>
      </Modal.Body>
      <Modal.Footer className="dark-mode">
        <button type="button" className="btn btn-secondary" onClick={() => onClose(false)}>
          Cancel
        </button>
        <button className="btn btn-success" type="button" onClick={() => onClose(true)}>
          Confirm
        </button>
      </Modal.Footer>
    </Modal>
  );
}

export default RenewUserAccessModal;
